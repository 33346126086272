<template>
  <div class="container">
    <div class="match-feed">
      <div class="mf-header">
        <div class="mf-team">
          <div class="team-profile">
            <div class="logo">
              <img src="~@/img/test-logo2.png" alt="#">
            </div>
            <div class="name">NAVI</div>
          </div>
        </div>
        <div class="mf-team reverse">
          <div class="team-profile">
            <div class="logo">
              <img src="~@/img/test-logo2.png" alt="#">
            </div>
            <div class="name">NAVI</div>
          </div>
        </div>
      </div>
      <div class="mf-bg">
        <div class="mf-content_wrapper">
          <div class="mf-content">
            <div class="initial-info">
              <div class="initial-info__head">
                <div class="time">00:00</div>
                <div class="text">Начало матча</div>
                <div class="small">Стартовые составы</div>
              </div>
              <div class="panel">
                <div class="players">
                  <div class="player-block captain">
                    <div class="logo">
                      <img alt="#" src="~@/img/testAvatar/golfer.png">
                    </div>
                    <div class="name">test</div>
                  </div>
                  <div class="player-block">
                    <div class="logo">
                      <img alt="#" src="~@/img/testAvatar/golfer.png">
                    </div>
                    <div class="name">test</div>
                  </div>
                  <div class="player-block">
                    <div class="logo">
                      <img alt="#" src="~@/img/testAvatar/golfer.png">
                    </div>
                    <div class="name">test</div>
                  </div>
                  <div class="player-block">
                    <div class="logo">
                      <img alt="#" src="~@/img/testAvatar/golfer.png">
                    </div>
                    <div class="name">test</div>
                  </div>
                  <div class="player-block">
                    <div class="logo">
                      <img alt="#" src="~@/img/testAvatar/golfer.png">
                    </div>
                    <div class="name">test</div>
                  </div>
                </div>
              </div>
              <div class="panel reverse">
                <div class="players">
                  <div class="player-block captain">
                    <div class="logo">
                      <img alt="#" src="~@/img/testAvatar/golfer.png">
                    </div>
                    <div class="name">test</div>
                  </div>
                  <div class="player-block">
                    <div class="logo">
                      <img alt="#" src="~@/img/testAvatar/golfer.png">
                    </div>
                    <div class="name">test</div>
                  </div>
                  <div class="player-block">
                    <div class="logo">
                      <img alt="#" src="~@/img/testAvatar/golfer.png">
                    </div>
                    <div class="name">test</div>
                  </div>
                  <div class="player-block">
                    <div class="logo">
                      <img alt="#" src="~@/img/testAvatar/golfer.png">
                    </div>
                    <div class="name">test</div>
                  </div>
                  <div class="player-block">
                    <div class="logo">
                      <img alt="#" src="~@/img/testAvatar/golfer.png">
                    </div>
                    <div class="name">test</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="event">
              <div class="score">
                1 : 0
              </div>
              <div class="event-time">03:23</div>
              <div class="panel">
                <div class="event-title">Выиграли раунд</div>
                <div class="event-desc">Взрыв бомбы</div>
              </div>
            </div>
            <div class="event reverse">
              <div class="score">
                1 : 1
              </div>
              <div class="event-time">03:23</div>
              <div class="panel">
                <div class="event-title">Выиграли раунд</div>
                <div class="event-desc">Взрыв бомбы</div>
              </div>
              <div class="event-time">03:23</div>
              <div class="panel">
                <div class="event-title">Выиграли раунд</div>
                <div class="event-desc">Взрыв бомбы</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SingleMatchFeed"
}
</script>

<style scoped>

</style>